import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { getTime } from "../../../../../../../logic/useGetTime";
import { removeEmptyStringKeys } from "../../../../../../../utils/utils";
import TableNoData from "../../../../../../../components/Table/TableNoData";
import { Button } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DFE3E8",
    fontWeight: 700,
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row(props) {
  const { row } = props;
  console.log("🚀 ~ file: AssociatedEntryDecisions.jsx:38 ~ Row ~ row:", row?.id)

  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row?.eventType}
        </StyledTableCell>
        <StyledTableCell>
          {row?.eventTime && getTime(row?.eventTime, row?.storeTimeZone)}
        </StyledTableCell>
        <StyledTableCell>{row?.name}</StyledTableCell>
        <StyledTableCell>{row?.cartRef}</StyledTableCell>
        <StyledTableCell>
          <Button variant="text" sx={{ textTransform: "capitalize" }} onClick={() => history.push(`/support/decision-events/entry-decision/${row?.id}`)}>
            Review
          </Button>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddinANHOODY001gTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                HistoryANHOODY001
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Event Type</StyledTableCell>
                    <StyledTableCell>Event Timestamp</StyledTableCell>
                    <StyledTableCell>Shopper Name</StyledTableCell>
                    <StyledTableCell>Cart Ref</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.history?.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <StyledTableCell component="th" scope="row">
                        {historyRow?.date}
                      </StyledTableCell>
                      <StyledTableCell>
                        {historyRow?.customerId}
                      </StyledTableCell>
                      <StyledTableCell>{historyRow?.amount}</StyledTableCell>
                      <StyledTableCell>
                        {Math.round(historyRow?.amount * row.price * 100) / 100}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function AssociatedEntryDecisonsTable({
  value,
  associatedEntryData,
}) {
  const { data: AssociatedEntryDecisonsData, isLoading } = useQuery(
    ["associated entry decisions", associatedEntryData],
    async () =>
      axios.get(
        `${process.env.REACT_APP_STORE_URL}/user-activities?sort=entryTime%2Casc&eventStatus.in=AUTHORIZED%2CTRACKED`,
        {
          params: {
            ...removeEmptyStringKeys(associatedEntryData),
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          },
        }
      ),
    {
      enabled: true,
      cacheTime: Infinity,
      staleTime: 1000,
    }
  );

  const isNotFound =
    AssociatedEntryDecisonsData?.data?.length === 0 && !isLoading;

  console.log("AssociatedEntryDecisonsData", AssociatedEntryDecisonsData?.data);

  return (
    <TableContainer component={Paper} style={{ maxHeight: 700 }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>Event Type</StyledTableCell>
            <StyledTableCell>Store code</StyledTableCell>
            <StyledTableCell>Event Timestamp</StyledTableCell>
            <StyledTableCell>Shopper Name</StyledTableCell>
            <StyledTableCell>Cart Ref</StyledTableCell>
            <StyledTableCell>Action </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {AssociatedEntryDecisonsData?.data?.map((row, i) => (
            <Row key={i} row={row} />
          ))}
          <TableNoData isNotFound={isNotFound} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
