import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import axios from "axios";
import { BiSearchAlt } from "react-icons/bi";
import FadeLoader from "react-spinners/FadeLoader";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useQueryGet } from "../../../../../../../../hook/useQueryGet";
import CustomSearch from "../../../../../../../../components/searchbar";
import Iconify from "../../../../../../../../components/iconify/Iconify";
import { Autocomplete, Button } from "@mui/material";
import { areAllValuesEmpty } from "../../../../../../../../utils/utils";

function All({ url, viewUrl, editUrl, listUrl, getName }) {
  let [loading, setLoading] = useState(false);
  const [allCount, setAllCount] = useState();
  const history = useHistory();

  const { t } = useTranslation();

  const [selectProduct, setSelectProduct] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const [search, setSearch] = useState({
    catby: "",
    nameby: "",
    barcodeby: "",
    filterby: "",
  });

  const [filter, setFilter] = useState({
    "category.contains": "",
    "name.contains": "",
    "barCode.contains": "",
  });

  const handleInput = (e) => {
    handlePageChange(null, 1);
    setFilter(() => ({
      ...filter,
      [e.target.name || e.name]: e.target.value || e.value,
    }));
  };

  const clear = () => {
    setFilter({
      "category.contains": "",
      "name.contains": "",
      "barCode.contains": "",
    });
    setSelectProduct(null);
    setFilteredData(null);
  };

  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const { data: productLists } = useQueryGet(
    `${process.env.REACT_APP_BASE_URL}/_search/products`,
    ["productLists", activePage, filter],
    true,
    {
      ...filter,
      "rootOrgId.equals": parseInt(
        JSON.parse(localStorage.getItem("dashboard-data"))?.id
      ),
      page: activePage - 1,
      size: 10,
    }
  );

  useEffect(() => {
    if (productLists?.headers?.["x-total-count"])
      setAllCount(productLists?.headers?.["x-total-count"]);
  }, [productLists]);

  console.log("productLists", productLists);

  const isShowClearIcon = !areAllValuesEmpty(filter);

  return (
    <div className="w-full h_das_table select-text">
      <div className="flex h-10 w-full items-center mt-2 py-8 px-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
          <CustomSearch
            label="Search by Name"
            name="name.contains"
            value={filter?.["name.contains"]}
            onChange={(e) => handleInput(e)}
            inputPropsStyle={{ borderRadius: "0.75rem" }}
            handleClear={() => {
              setFilter({ ...filter, "name.contains": "" });
            }}
            isClearIcon
          />
          <CustomSearch
            label="Search by Barcode"
            name="barCode.contains"
            value={filter?.["barCode.contains"]}
            onChange={(e) => handleInput(e)}
            inputPropsStyle={{ borderRadius: "0.75rem" }}
            handleClear={() => {
              setFilter({ ...filter, "barCode.contains": "" });
            }}
            isClearIcon
          />
          <CustomSearch
            label="Search by Category"
            name="category.contains"
            value={filter?.["category.contains"]}
            onChange={(e) => handleInput(e)}
            inputPropsStyle={{ borderRadius: "0.75rem" }}
            handleClear={() => {
              setFilter({ ...filter, "category.contains": "" });
            }}
            isClearIcon
          />

          {/* <Autocomplete
            // filterOptions={(options) => options}
            id="controlled-demo"
            options={filterByStatus}
            onChange={(e, v, reason) => {
              console.log("🚀 ~ file: All.js:230 ~ All ~ reason:", reason);
              handlePageChange(null, 1);
              if (reason === "clear") {
               
              } else {
              
              }
            }}
            value={activeMerchant}
            renderInput={(params) => (
              <TextField {...params} size="small" label={"Filter by Status"} />
            )}
            sx={{ "& fieldset": { borderRadius: "0.75rem" } }}
          /> */}
          {isShowClearIcon && (
            <Button
              color="error"
              size="small"
              onClick={() => clear()}
              startIcon={<Iconify icon="eva:trash-2-outline" />}
            >
              Clear
            </Button>
          )}
        </div>
      </div>
      {/* <div className="my-5">
        <div
          className={
            loading
              ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
              : null
          }
        >
          <FadeLoader className="absolute " loading={loading} size={150} />
        </div>
        <div className="flex h-10 w-full mt-2 items-center justify-between py-8 px-4">
          <div className="w-1/4 flex justify-end items-center">
            <input
              type="number"
              onChange={(e) => handleInput(e.target.value, "barcodeby")}
              className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
              placeholder={`${t("searchBarcode")}`}
              value={search?.barcodeby}
            />
            <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
          </div>

          <div className="w-1/4 flex justify-end items-center">
            <input
              onChange={(e) => handleInput(e.target.value, "nameby")}
              className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
              placeholder={`${t("search by name")}`}
              value={search?.nameby}
            />
            <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
          </div>

          <div className="w-1/4 flex justify-end items-center">
            <input
              onChange={(e) => handleInput(e.target.value, "catby")}
              className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
              placeholder={`${t("search by category")}`}
              value={search?.catby}
            />
            <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
          </div>

          <div style={{ width: "25%" }}>
          <Select
            className="text-left"
            placeholder={`${t("searchProduct")}`}
            maxMenuHeight={162}
            value={search?.catby}
            onChange={(value)=>productHandler(value,"catby")}
            options={data?.map((item, index) => {
              return {
                label: item?.name,
                value: item?.barCode,
                key: index,
              };
            })}
          />
        </div>

          <div className="w-48 flex justify-end">
            <button
              onClick={clear}
              className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
            >
              {t("clearFilter")}
            </button>
          </div>
        </div>
      </div> */}

      <div className="ag-theme-alpine w-full h-full text-left select-text">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          pagination={false}
          rowData={productLists?.data || []}
          onRowClicked={(params) =>
            history.push({
              pathname: viewUrl,
              state: { data: params.data },
            })
          }
        >
          <AgGridColumn
            headerName={t("productName")}
            field="name"
            minWidth={400}
            sortable={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn
            headerName={"UOM"}
            field="unitOfMeasure"
            minWidth={80}
            maxWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">{params?.data?.unitOfMeasure}</p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName={t("unitWeight")}
            field="unitWeight"
            minWidth={80}
            maxWidth={150}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div className="flex">
                  <p className="ml-4 mr-2">
                    {parseFloat(params?.data?.unitWeight).toFixed(4)}
                  </p>
                  {params?.data?.unitOfMeasure &&
                    params?.data?.unitWeight &&
                    (params?.data?.unitOfMeasure?.toLowerCase() == "gm" ||
                    params?.data?.unitOfMeasure?.toLowerCase() == "ml"
                      ? params?.data?.unitWeight <= 1
                      : params?.data?.unitWeight * 1000 <= 1) && (
                      <img alt="" src={"/icons/warningIcon.svg"} />
                    )}
                </div>
              );
            }}
          />

          {/* <AgGridColumn
            headerName={t("categories")}
            field="productCategories"
            minWidth={110}
            cellStyle={{ textAlign: "center" }}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  {params?.data?.productCategories?.map((cat, index) => {
                    <p key={index}>{cat}</p>;
                  })}
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName={t("purchasePrice")}
            field="purchasePrice"
            minWidth={100}
            maxWidth={150}
            sortable={true}
            //cellStyle={{ textAlign: "center" }}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {params?.data?.currencySymbol}{" "}
                    {parseFloat(params?.data?.purchasePrice).toFixed(2)}
                  </p>
                </>
              );
            }}
          />
          <AgGridColumn
            headerName={t("Gross Selling Price")}
            field="sellPriceWithTax"
            // cellStyle={{ textAlign: "center" }}
            minWidth={120}
            maxWidth={150}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {params?.data?.currencySymbol}{" "}
                    {parseFloat(params?.data?.sellPriceWithTax).toFixed(2)}
                  </p>
                </>
              );
            }}
            sortable={true}
          />

          <AgGridColumn
            headerName={t("Net Selling Price")}
            field="sellPrice"
            // cellStyle={{ textAlign: "center" }}
            minWidth={120}
            maxWidth={150}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {params?.data?.currencySymbol}{" "}
                    {parseFloat(params?.data?.sellPrice).toFixed(2)}
                  </p>
                </>
              );
            }}
            sortable={true}
          />
          <AgGridColumn
            headerName={t("barcode")}
            field="barCode"
            minWidth={140}
            maxWidth={180}
            sortable={true}
          />
          {/* <AgGridColumn
            headerName={t("packageType")}
            field="packageType"
            //cellStyle={{ textAlign: "center" }}
            minWidth={120}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">{params?.data?.packageType}</p>
                </>
              );
            }}
          /> */}

          {/* <AgGridColumn
            headerName={t("Status")}
            field="status"
            minWidth={80}
            cellRendererFramework={(params) => <Confirm params={params} />}
          /> */}

          <AgGridColumn
            headerName={t("Action")}
            field="action"
            minWidth={100}
            maxWidth={200}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
              />
            )}
          />
        </AgGridReact>
      </div>
      <div className="  absolute bottom-8 right-12  flex justify-center item-center ">
        <Pagination
          count={
            allCount % 10 === 0
              ? parseInt(allCount / 10)
              : parseInt(allCount / 10) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default All;
