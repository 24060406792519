import React, { useState, useEffect } from "react";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Address from "./Address";
import Select from "react-select";
import DashboardRoute from "./DashboardRoute";
import Confirm from "./Confirm";
import moment from "moment";
import axios from "axios";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { usePut } from "../../../../../../api/usePut";
import { toast } from "react-toastify";
import { useGet } from "../../../../../../api/useGet";
import { Autocomplete, Button, Pagination, TextField } from "@mui/material";
import { useQueryGet } from "../../../../../../hook/useQueryGet";
import CustomSearch from "../../../../../../components/searchbar";
import SearchableDropdown from "../../../../../../components/dropdown";
import Iconify from "../../../../../../components/iconify/Iconify";
import { areAllValuesEmpty } from "../../../../../../utils/utils";
import ActivationShopper from "../../../../../admin/merchant/dashboard/pages/shoppers/options/ActivationShopper";

const filterByStatus = [
  {
    value: "blocked.equals",
    label: "Block",
  },
  {
    value: "onHoldStatus.equals",
    label: "On hold",
  },
];

function All({ data, isOrg }) {
  const rootOrgId = parseInt(
    JSON.parse(localStorage.getItem("dashboard-data"))?.id
  );
  const history = useHistory();

  const [retailerStore, setRetailerStore] = useState([]);

  const [filter, setFilter] = useState({
    "firstName.equals": "",
    "login.equals": "",
    "phone.equals": "",
    "rootOrgId.equals": isOrg ? rootOrgId : "",
  });

  const [activePage, setActivePage] = useState(1);

  const [refresh, setRefresh] = useState(false);

  const [activeMerchant, setActiveMerchant] = useState("");

  const { data: ShoppersList, isApiLoading } = useQueryGet(
    `${process.env.REACT_APP_SHOPPER_URL}/users`,
    ["shopperData", activePage, filter, refresh],
    true,
    {
      ...filter,
      page: activePage - 1,
      size: 10,
      sort: "createdDate,desc",
      "rootOrgId.equals": isOrg ? rootOrgId : filter?.["rootOrgId.equals"],
    }
  );
  console.log(
    "🚀 ~ file: All.js:60 ~ All ~ ShoppersList:",
    ShoppersList?.headers?.["x-total-count"]
  );

  // const dataFilter = (data) => {
  //   var filterData = data?.filter(
  //     (el, index, self) => index === self.findIndex((t) => t.name === el.name)
  //   );
  //   return filterData;
  // };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/retail-stores?sort=createdDate,desc`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
    })
      .then((res) => {
        // setRetailerStore(dataFilter(res?.data));
        setRetailerStore(res?.data);
        //console.log("data: ", res?.data);
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: orgs } = useGet(
    `${process.env.REACT_APP_BASE_URL}/orgs?sort=createdDate,desc`,
    "orgs"
  );

  const clear = () => {
    setFilter({
      "firstName.equals": "",
      "login.equals": "",
      "phone.equals": "",
      "rootOrgId.equals": isOrg ? rootOrgId : "",
    });
    setActiveMerchant("");
  };

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const handleInput = (e) => {
    handlePageChange(null, 1);
    setFilter(() => ({
      ...filter,
      [e.target.name || e.name]: e.target.value || e.value,
    }));
  };

  const handleClear = (e) => {
    console.log(
      "🚀 ~ file: All.js:117 ~ handleClear ~ e:",
      e.target.name,
      e.name,
      e.target.value,
      e.value
    );
  };

  const isShowClearIcon = !areAllValuesEmpty(filter);

  return (
    <div className=" w-full h-[calc(100vh-184px)]">
      <div className="flex h-10 w-full items-center mt-2 py-8 px-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
          <CustomSearch
            label="Search by Name"
            name="firstName.equals"
            value={filter?.["firstName.equals"]}
            onChange={(e) => handleInput(e)}
            inputPropsStyle={{ borderRadius: "0.75rem" }}
            handleClear={() => {
              setFilter({ ...filter, "firstName.equals": "" });
            }}
            isClearIcon
            // onKeyDown={(e) => handleKeyDown(e)}
          />
          <CustomSearch
            label="Search by Login"
            name="login.equals"
            value={filter?.["login.equals"]}
            onChange={(e) => handleInput(e)}
            inputPropsStyle={{ borderRadius: "0.75rem" }}
            handleClear={() => {
              setFilter({ ...filter, "login.equals": "" });
            }}
            isClearIcon
            // onKeyDown={(e) => handleKeyDown(e)}
          />
          <CustomSearch
            label="Search by Phone"
            name="phone.equals"
            value={filter?.["phone.equals"]}
            onChange={(e) => handleInput(e)}
            inputPropsStyle={{ borderRadius: "0.75rem" }}
            handleClear={() => {
              setFilter({ ...filter, "phone.equals": "" });
            }}
            isClearIcon
            // onKeyDown={(e) => handleKeyDown(e)}
          />
          {/* <SearchableDropdown
            options={
              orgs?.map((item) => ({
                value: item?.id,
                label: item?.name,
              })) || []
            }
            showClearIcon
            name="rootOrgId.equals"
            label={"Filter by Merchats"}
            value={activeMerchant}
            onChange={(e, v) => {
              handlePageChange(null, 1);
              setFilter({ ...filter, "rootOrgId.equals": v?.value });
              setActiveMerchant(v?.label);
            }}
            sx={{ borderRadius: "0.75px" }}
          /> */}
          {!isOrg && (
            <Autocomplete
              // filterOptions={(options) => options}
              id="controlled-demo"
              options={
                orgs?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })) || []
              }
              onChange={(e, v) => {
                handlePageChange(null, 1);
                setFilter({ ...filter, "rootOrgId.equals": v?.value });
                // setActiveMerchant(v?.label);
              }}
              value={
                orgs?.find((item) => item?.id == filter?.["rootOrgId.equals"])
                  ?.name || ""
              }
              renderInput={(params) => {
                console.log(params);
                return (
                  <TextField
                    {...params}
                    size="small"
                    label={"Filter by Merchants"}
                  />
                );
              }}
              sx={{ "& fieldset": { borderRadius: "0.75rem" } }}
            />
          )}
          <Autocomplete
            // filterOptions={(options) => options}
            id="controlled-demo"
            options={filterByStatus}
            onChange={(e, v, reason) => {
              console.log("🚀 ~ file: All.js:230 ~ All ~ reason:", reason);
              handlePageChange(null, 1);
              if (reason === "clear") {
                setFilter((filter) => {
                  if (filter?.["blocked.equals"])
                    delete filter?.["blocked.equals"];
                  if (filter?.["onHoldStatus.equals"])
                    delete filter?.["onHoldStatus.equals"];
                  return { ...filter };
                });
                setActiveMerchant("");
              } else {
                setFilter((filter) => {
                  if (filter?.["blocked.equals"])
                    delete filter?.["blocked.equals"];
                  if (filter?.["onHoldStatus.equals"])
                    delete filter?.["onHoldStatus.equals"];
                  return { ...filter, [v?.value]: true };
                });
                setActiveMerchant(v?.label);
              }
            }}
            value={activeMerchant}
            renderInput={(params) => (
              <TextField {...params} size="small" label={"Filter by Status"} />
            )}
            sx={{ "& fieldset": { borderRadius: "0.75rem" } }}
          />
          {isShowClearIcon && (
            <Button
              color="error"
              size="small"
              onClick={() => clear()}
              startIcon={<Iconify icon="eva:trash-2-outline" />}
            >
              Clear
            </Button>
          )}
          {/* <button
            onClick={clear}
            className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
          >
            Clear filters
          </button> */}
        </div>
      </div>

      <div className="ag-theme-alpine  w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          // pagination={true}
          // paginationPageSize={50}
          rowData={ShoppersList?.data ? ShoppersList?.data : data}
        >
          <AgGridColumn
            headerName="Name"
            field="firstName"
            minWidth={180}
            sortable={true}
            // headerCheckboxSelection={true}
            // headerCheckboxSelectionFilteredOnly={true}
            // checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute data={params?.data} />
            )}
          />
          <AgGridColumn field="login" minWidth={150} sortable={true} />

          <AgGridColumn
            headerName="Store Code"
            field="retailStoreCode"
            minWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>{params?.data?.userInfoDTO?.retailStoreCode}</p>
                  {/* <NumberFormat displayType={'text'} value={params?.data?.userInfoDTO?.phone} format="###-###-####"  /> */}
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="User's Phone"
            field="phone"
            minWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>{params?.data?.userInfoDTO?.phone}</p>
                  {/* <NumberFormat displayType={'text'} value={params?.data?.userInfoDTO?.phone} format="###-###-####"  /> */}
                </>
              );
            }}
          />

          <AgGridColumn field="email" minWidth={240} sortable={true} />

          <AgGridColumn
            field="onHoldStatus"
            minWidth={100}
            cellRendererFramework={(params) => (
              <Confirm
                params={params}
                handleRefresh={() => setRefresh((item) => !item)}
              />
            )}
          />

          <AgGridColumn
            field="activationStatus"
            headerName="Is Blocked?"
            minWidth={100}
            cellRendererFramework={(params) => (
              <ActivationShopper
                params={params}
                handleRefresh={() => setRefresh((item) => !item)}
              />
            )}
          />
          <AgGridColumn
            field="idVerifyStatus"
            headerName="ID Verification Status"
            cellRendererFramework={(params) => {
              return (
                <>
                  <p className="ml-4">
                    {params?.data?.userInfoDTO?.identityVerifiedStatus
                      ? "Yes"
                      : "No"}
                  </p>
                </>
              );
            }}
          />
        </AgGridReact>
        <div className="flex justify-center item-center">
          <Pagination
            count={
              Math.ceil(ShoppersList?.headers?.["x-total-count"] / 10) || "0"
            }
            showFirstButton
            showLastButton
            page={activePage}
            onChange={handlePageChange}
            // renderItem={(item) => <PaginationItem component="div" {...item} />}
            // onLoad={}
          />
        </div>
      </div>
    </div>
  );
}

export default All;
