import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useQuery } from "react-query";
import axios from "axios";

import { getTime } from "../../../../../../../logic/useGetTime";
import { removeEmptyStringKeys } from "../../../../../../../utils/utils";
import TableNoData from "../../../../../../../components/Table/TableNoData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DFE3E8",
    fontWeight: 700,
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function OpenEntryDecisionTable({ value, openEntryData }) {
  const { data: OpenEntryDecisonsData, isLoading } = useQuery(
    ["open entry decisions", openEntryData, value],
    async () =>
      axios.get(
        `${process.env.REACT_APP_STORE_URL}/entry-decision-events?sort=eventTime%2Casc&eventType.equals=CameraActivity`,
        {
          params: {
            ...removeEmptyStringKeys(openEntryData),
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user-info")}`,
          },
        }
      ),
    {
      enabled: true,
      cacheTime: Infinity,
      staleTime: 1000,
    }
  );

  const isNotFound =
    (OpenEntryDecisonsData?.data?.length === 0 && !isLoading) ||
    OpenEntryDecisonsData?.data === undefined;

  console.log("OpenEntryDecisonsData", OpenEntryDecisonsData?.data);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Event Type</StyledTableCell>
            <StyledTableCell>Store code</StyledTableCell>
            <StyledTableCell>Event Timestamp</StyledTableCell>
            <StyledTableCell>Shopper Name</StyledTableCell>
            <StyledTableCell>Cart Ref</StyledTableCell>
            <StyledTableCell>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {OpenEntryDecisonsData?.data?.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell component="th" scope="row">
                {row?.eventType}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row?.retailStoreCode}
              </StyledTableCell>
              <StyledTableCell>
                {row?.eventTime && getTime(row?.eventTime, row?.storeTimeZone)}
              </StyledTableCell>
              <StyledTableCell>{row?.CartRef}</StyledTableCell>
              <StyledTableCell>{row?.action}</StyledTableCell>
              <StyledTableCell>{row?.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
          <TableNoData isNotFound={isNotFound} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
