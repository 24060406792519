import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import { useGet } from "../../../../../../api/useGet";
import { isAnyValuesEmpty } from "../../../../../../utils/utils";
import { usePost } from "../../../../../../api/usePost";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { set } from "date-fns";
import { usePut } from "../../../../../../api/usePut";
import { usePatch } from "../../../../../../api/usePatch";

const AudioalertModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    retailStoreId: "",
    rootOrgId: "",
    deviceIdentifier: "",
    alertType: "GHOST-SHOPPER",
  });
  console.log(
    "🚀 ~ AudioalertModal ~ formData:",
    formData,
    !isAnyValuesEmpty({
      retailStoreId: formData?.retailStoreId,
      rootOrgId: formData?.rootOrgId,
    })
  );

  const { data: allRetailderStores } = useGet(
    `${process.env.REACT_APP_BASE_URL}/retail-stores?page=0&size=100&sort=createdDate,desc`,
    "allRetailderStores"
  );
  console.log("🚀 ~ AudioalertModal ~ allRetailderStores:", allRetailderStores);

  const { data: machines } = useGet(
    `${process.env.REACT_APP_BASE_URL}/machines?rootOrgId.equals=${formData?.rootOrgId}&type.equals=audio&retailStoreId.equals=${formData?.retailStoreId}`,
    ["machines", formData?.rootOrgId, formData?.retailStoreId],
    !isAnyValuesEmpty({
      retailStoreId: formData?.retailStoreId,
      rootOrgId: formData?.rootOrgId,
    })
  );
  console.log("🚀 ~ AudioalertModal ~ machines:", machines);

  const submitApi = usePost(
    `${process.env.REACT_APP_BASE_URL}/machines/play-audio`,
    "submitApi"
  );

  const handleSubmit = () => {
    if (isAnyValuesEmpty(formData)) {
      toast.error("Please select store and machine");
      return;
    }
    submitApi.mutate({
      deviceIdentifier: formData?.deviceIdentifier,
      alertType: formData?.alertType,
    });
  };

  useEffect(() => {
    if (submitApi.isSuccess) {
      toast.success("Audio alert sent successfully");
      submitApi.reset();
    }
  }, [submitApi]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          textAlign="center"
          sx={{ mb: 2 }}
        >
          Audio alert
        </Typography>
        {/* <Divider sx={{ mb: 3 }} /> */}
        <div className="flex justify-center items-center mb-4">
          <p className="font-bold mr-3 w-[120px]">Store</p>
          <Select
            value={
              allRetailderStores?.find(
                (item) => item?.id == formData?.retailStoreId
              )?.name
            }
            fullWidth
            // displayEmpty
            // sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled>
              Store
            </MenuItem>
            {allRetailderStores?.map((store) => {
              console.log("🚀 ~ {allRetailderStores?.map ~ store:", store);
              return (
                <MenuItem
                  value={store?.id}
                  onClick={(e) => {
                    console.log("eeeee", store);
                    setFormData(() => ({
                      ...formData,
                      rootOrgId: store?.rootOrgId,
                      retailStoreId: store?.id,
                      deviceIdentifier: "",
                    }));
                  }}
                >
                  {store?.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div className="flex justify-center items-center mb-4">
          <p className="font-bold mr-3 w-[120px]">Machine</p>
          <Select
            value={
              machines?.find(
                (item) => item?.deviceIdentifier == formData?.deviceIdentifier
              )?.deviceIdentifier
            }
            onChange={(e) =>
              setFormData({ ...formData, deviceIdentifier: e.target.value })
            }
            key={formData?.retailStoreId}
            fullWidth
          >
            <MenuItem value="" disabled>
              Store
            </MenuItem>
            {machines?.map((machine) => {
              console.log("🚀 ~ {machines?.map ~ machine:", machine);
              return (
                <MenuItem value={machine?.deviceIdentifier}>
                  {machine?.name} - {machine?.deviceIdentifier}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div className="flex justify-center items-center mb-4">
          <p className="font-bold mr-3 w-[120px]">Alert Type</p>
          <TextField
            fullWidth
            // required
            margin="normal"
            label=""
            name="alertType"
            value={formData?.alertType}
            onChange={(e) =>
              setFormData({ ...formData, alertType: e.target.value })
            }
          />
        </div>
        <LoadingButton
          fullWidth
          color="success"
          variant="contained"
          disabled={isAnyValuesEmpty({
            retailStoreId: formData?.retailStoreId,
            deviceIdentifier: formData?.deviceIdentifier,
            alertType: formData?.alertType,
          })}
          isLoading={submitApi?.isLoading}
          onClick={handleSubmit}
        >
          Submit request
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default AudioalertModal;
