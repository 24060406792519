import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import Action from "./Action";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Select from "react-select";
import DashboardRoute from "./refunded/DashboardRoute";
import axios from "axios";
import { BiSearchAlt, BiErrorCircle } from "react-icons/bi";
import { AiOutlineFileDone } from "react-icons/ai";
import FadeLoader from "react-spinners/FadeLoader";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { TiTick } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import { useQuery } from "react-query";
import { getTime } from "../../../../../../logic/useGetTime";
import { toast } from "react-toastify";
import { fields } from "../../../../../../statusField";
import {
  areAllValuesEmpty,
  removeEmptyStringKeys,
} from "../../../../../../utils/utils";
import CustomSearch from "../../../../../../components/searchbar";
import Iconify from "../../../../../../components/iconify/Iconify";
import { Button, Stack } from "@mui/material";
import "../../../../../../styles/agGridScrollBarStyle.css";
import {
  MerchantsDetails,
  REFUND_APPROVED,
} from "../../../../../../utils/action_types";
import { getDecryptedData } from "../../../../../../utils/localStorageUtils";
import { usePost } from "../../../../../../api/usePost";
import RefundWarningModal from "../modal/RefundWarningModal";
import CustomDropdown from "../../../../../../components/dropdown";
import { useGet } from "../../../../../../api/useGet";

function Refunded({ listUrl, merchant, orgName }) {
  const { RangePicker } = DatePicker;

  const itemsPerPageOptions = [10, 15, 20];

  const [searchValue, setSearchValue] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  const [retailName, setRetailerName] = useState(null);

  // let [loading, setLoading] = useState(false);

  const history = useHistory();

  const [input, setInput] = useState({
    "login.contains": "",
    "orderNumber.contains": "",
    "createdDateFrom.greaterThanOrEqual": "",
    "createdDateTo.lessThanOrEqual": "",
    "storeCode.equals": "",
  });

  const [params, setParams] = useState({
    id: "",
    submitId: "",
  });

  // const statusOptions = [
  //   {
  //     label: "Refund Processed",
  //     value: "REFUND_PROCESSED",
  //   },
  //   {
  //     label: "Refund Approved",
  //     value: "REFUND_APPROVED",
  //   },
  // ];
  const [currentData, setCurrentData] = useState();

  const [select, setSelect] = useState({
    status: "",
  });

  const [activePage, setActivePage] = useState(1);

  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);

  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

  const isShowClearIcon = !areAllValuesEmpty(input);

  const [open, setOpen] = useState(false);

  // const findUrl = (page, name, sessionRef, orderNo, status) => {
  //   if (name && sessionRef && orderNo && status) {
  //     return `${listUrl}&page=${
  //       page - 1
  //     }&size=10&firstName.equals=${name}&sessionRef.equals=${sessionRef}&orderNumber.equals=${orderNo}&status.equals=${status}`;
  //   } else if (name) {
  //     return `${listUrl}&page=${page - 1}&size=10&firstName.equals=${name}`;
  //   } else if (sessionRef) {
  //     return `${listUrl}&page=${
  //       page - 1
  //     }&size=10&sessionRef.equals=${sessionRef}`;
  //   } else if (orderNo) {
  //     return `${listUrl}&page=${
  //       page - 1
  //     }&size=10&orderNumber.equals=${orderNo}`;
  //   } else if (status) {
  //     return `${listUrl}&page=${page - 1}&size=10&status.equals=${status}`;
  //   } else {
  //     return `${listUrl}&page=${page - 1}&size=10`;
  //   }
  // };

  // const fetchData = async (page, login, orderNo, status) => {
  //   try {
  //     const res = await axios({
  //       method: "GET",
  //       url: findUrl(page, login, orderNo, status),
  //       params: {},
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("user-info")}`,
  //       },
  //     });
  //     return res.data;
  //   } catch ({ message }) {
  //     if (message === "Request failed with status code 401") {
  //       history.push("/login");
  //     } else {
  //       toast.error(message);
  //     }
  //   }
  // };

  const {
    data,
    isLoading: loading,
    isIdle,
    error,
  } = useQuery(
    [
      "refundList",
      activePage,
      input,
      itemsPerPage,
      select?.status?.value,
      params?.id,
      params?.submitId,
    ],
    async () =>
      axios.get(`${listUrl}`, {
        params: {
          ...removeEmptyStringKeys(input),
          page: activePage - 1,
          size: itemsPerPage,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }),
    {
      enabled:
        !!activePage ||
        !!input?.login ||
        !!input?.orderNo ||
        !!input?.["createdDateFrom.greaterThanOrEqual"] ||
        !!input?.["createdDateTo.lessThanOrEqual"] ||
        !!select?.status?.value ||
        !!params?.id ||
        !!params?.submitId,
      cacheTime: 5,
    }
  );
  console.log(
    "🚀 ~ file: Refunded.js:106 ~ Refunded ~ data:",
    data?.headers?.["x-total-count"]
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  // useEffect(() => {
  //   fetchRetailerName();
  // }, []);

  const handleInput = (e, name) => {
    const query = e.target.value.toLowerCase();
    handlePageChange(null, 1);

    setInput({ ...input, [name]: e.target.value });
  };

  const onSelectHandler = (value, name) => {
    setSelect({ ...select, [name]: value });
  };

  const clear = () => {
    setInput({
      login: "",
      orderNumber: "",
      "createdDateFrom.greaterThanOrEqual": "",
      "createdDateTo.lessThanOrEqual": "",
      "storeCode.equals": "",
    });
    setSearchValue(null);
    setSelectedDateRange([null, null]);
    setSelect({ status: "" });
    // retailStoresRefetch();
  };

  // const refund = (data) => {
  //   localStorage.setItem("orderData", JSON.stringify(data));
  //   history.push("/order/refund");
  // };
  const handleLogs = usePost(
    `${process.env.REACT_APP_STORE_URL}/app-logging-infos`,
    "handleCaptureLogs"
  );

  const handleApprove = (data) => {
    if (data?.refundAmount > 5) {
      setCurrentData(data);
      setOpen(true);
    } else {
      approved(data);
    }
  };

  const approved = (data) => {
    console.log("approvedapproved", data);
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/approve-order-refund`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {
        id: data?.id,
        refundStatus: "REFUND_APPROVED",
        comments: "approved",
      },
    })
      .then(() => {
        setOpen(false);
        const loginInfo = getDecryptedData("loginInfo");
        const uniqID = localStorage.getItem("uniqID");
        handleLogs.mutate({
          actionName: REFUND_APPROVED,
          logTime: new Date().toISOString(),
          screenLoadingTime: `-`,
          logSessionId: uniqID,
          rootOrgId: data?.rootOrgId,
        });
        setParams({ ...params, id: data?.id });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const rejected = (data) => {
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/approve-order-refund`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: {
        id: data?.id,
        refundStatus: "REFUND_REJECTED",
        comments: "rejected",
      },
    })
      .then(() => {
        setParams({ ...params, id: data?.id });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const submit = (data) => {
    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_SHOPPER_URL}/order-refunds/${data?.id}/verify-order-refund`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("user-info")}`,
      },
      data: data,
    })
      .then(() => {
        setParams({ ...params, submitId: data?.id });
      })
      .catch(({ message }) => {
        if (message === "Request failed with status code 401") {
          history.push("/login");
        } else {
          toast.error(message);
        }
      });
  };

  const handleDateChange = (dates) => {
    console.log(
      "🚀 ~ file: Refunded.js:260 ~ handleDateChange ~ dates:",
      dates
    );
    if (dates.length === 2) {
      handlePageChange(null, 1);
      setInput({
        ...input,
        "createdDateFrom.greaterThanOrEqual": dates[0]?.toISOString(),
        "createdDateTo.lessThanOrEqual": dates[1]?.toISOString(),
      });
      setSelectedDateRange(dates);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setItemsPerPage(newPageSize);
    setActivePage(0);
    // setAc(1);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentData();
  };

  const { data: retailStores, refetch: retailStoresRefetch } = useGet(
    `${process.env.REACT_APP_BASE_URL}/_search/retail-stores?size=200`,
    "retailStores"
  );

  return (
    // <div className=" w-full h-[calc(100vh-152px)] select-none">
    <div className=" w-full h-full select-none">
      <div
        className={
          loading
            ? "bg-black bg-opacity-0 absolute inset-0 z-10 flex items-center justify-center"
            : null
        }
      >
        <FadeLoader className="absolute " loading={loading} size={150} />
      </div>
      {/* <div className="flex h-10 w-full items-center my-2 justify-between py-7 px-4"> */}
      {/* <div className="w-1/4 flex justify-end items-center">
          
          <input
            onChange={(e) => handleInput(e, "login")}
            type="number"
            className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
            placeholder="Search customer login"
            value={input?.login}
          />
          <BiSearchAlt className="w-12 h-5 absolute  text-blue-500" />
        </div> */}
      <Stack
        spacing={2}
        alignItems="center"
        direction={{
          xs: "column",
          sm: "row",
        }}
        sx={{ py: 3 }}
      >
        <CustomSearch
          label="Search by #login"
          name="firstName.contains"
          value={input?.login}
          onChange={(e) => handleInput(e, "login.contains")}
          // onKeyDown={(e) => handleKeyDown(e)}
        />
        <CustomSearch
          label="Search by #order"
          name="firstName.contains"
          value={input?.orderNumber}
          onChange={(e) => handleInput(e, "orderNumber.contains")}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*", // Optionally, you can use a regex pattern to enforce numeric input
          }}
          // onKeyDown={(e) => handleKeyDown(e)}
        />

        <RangePicker
          onChange={handleDateChange}
          value={selectedDateRange}
          clearIcon={null}
          style={{ height: "40px" }}
        />
        <CustomDropdown
          options={
            merchant
              ? [orgName]
              : retailStores?.map((item) => ({
                  value: item?.internalCode,
                  label: item?.name,
                })) || []
          }
          name="storeCode.equals"
          style={{ width: "200px" }}
          label={"Filter by Merchats"}
          value={
            merchant
              ? orgName
              : retailStores?.find(
                  (item) => item?.internalCode === input?.["storeCode.equals"]
                )?.name || ""
          }
          onChange={(e, v) => {
            handlePageChange(null, 1);
            setInput({ ...input, "storeCode.equals": v?.value });
          }}
        />
        {isShowClearIcon && (
          <Button
            color="error"
            size="small"
            onClick={() => clear()}
            startIcon={<Iconify icon="eva:trash-2-outline" />}
          >
            Clear
          </Button>
        )}
      </Stack>

      {/* <div className="w-1/4 flex justify-end items-center">
          <input
            onChange={(e) => handleInput(e, "orderNumber")}
            className="w-full focus:outline-none py-1.5 px-2 border-2 border-gray-200 rounded-lg"
            placeholder="Search Order Number"
            value={input?.orderNo}
          />
          <BiSearchAlt className="w-12  h-5 mr-4 absolute   text-blue-500" />
        </div> */}

      {/* <div style={{ width: "25%" }}> */}
      {/* <Select
            placeholder="Select Status"
            className="text-left"
            maxMenuHeight={200}
            value={select?.status}
            onChange={(value) => onSelectHandler(value, "status")}
            options={statusOptions}
          /> */}

      {/* </div> */}
      {/* <button
        onClick={clear}
        className="bg-green-600 py-1.5 px-5 mr-7 rounded text-white"
      >
        Clear filters
      </button> */}
      {/* </div> */}

      <div className="ag-theme-alpine w-full h-full text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 80,
            resizable: true,
          }}
          suppressRowClickSelection={true}
          pagination={false}
          paginationPageSize={10}
          rowData={filteredData ? filteredData : data?.data}
          domLayout="autoHeight"
        >
          <AgGridColumn
            headerName="Order No."
            field="orderNumber"
            minWidth={150}
            sortable={true}
            // headerCheckboxSelection={true}
            // headerCheckboxSelectionFilteredOnly={true}
            // checkboxSelection={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} />
            )}
          />

          <AgGridColumn headerName="Login" field="login" />
          <AgGridColumn
            headerName="Cart Ref"
            minWidth={120}
            sortable={true}
            cellRendererFramework={(params) => {
              return <p>{params?.data?.order?.sessionRef}</p>;
            }}
          />
          <AgGridColumn headerName="First Name" field="firstName" />
          <AgGridColumn headerName="Last Name" field="lastName" />
          {/* <AgGridColumn headerName="Offer type" field="offerType" /> */}

          <AgGridColumn
            field="status"
            headerName="Status"
            minWidth={200}
            maxWidth={200}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  {params?.data?.refundStatus === "IN_PROCESS" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "PAYMENT_PENDING" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus ===
                    "PAYMENT_CONFIRMATION_PENDING" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_PROCESSED" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_APPROVED" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_REJECTED" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "PAYMENT_REFUNDED" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_SUBMITTED" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "PAYMENT_REJECTED" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_FAILED" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus === "REFUND_POSTED" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : params?.data?.refundStatus ===
                    "REFUND_PAYMENT_CONFIRMATION_IN_PROCESS" ? (
                    <span className={fields[params?.data?.refundStatus]?.color}>
                      {fields[params?.data?.refundStatus]?.title}
                    </span>
                  ) : null}
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Merchant"
            field="storeInternalCode"
            cellRendererFramework={(params) => {
              return (
                <div className="h-[50px] w-[50px]">
                  <img
                    src={
                      MerchantsDetails.hasOwnProperty(params?.value)
                        ? MerchantsDetails?.[params?.value]?.imgUrl
                        : "/images/noImage.jpg"
                    }
                    width="50px"
                    height="50px"
                    alt="/images/noImage.jpg"
                    // className="w-[100px] h-[100px]"
                    // className="mt-3"
                  />
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="Store code"
            field="storeInternalCode"
            minWidth={150}
          />

          <AgGridColumn
            field="orderTotal"
            headerName="Total"
            minWidth={90}
            maxWidth={90}
            cellStyle={{ paddingRight: 10 }}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {/* {params?.data?.order.currencySymbol}{" "} */}
                    {params?.data?.formattedRefundAmountCurrency}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn
            headerName="Date"
            // minWidth={200}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {getTime(
                      params?.data?.refundOrderTime,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </>
              );
            }}
          />

          {/* <AgGridColumn
            headerName="Email"
            minWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>{params?.data?.userDTO?.email ? params.data?.userDTO?.email : "Nill"}</p>
                </>
              );
            }}
          /> */}

          <AgGridColumn
            headerName="Products"
            minWidth={300}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <div className="flex">
                  {params?.data?.order?.orderItems?.map((item, index) => {
                    return (
                      <p
                        key={index}
                        className="ml-0.5"
                      >{`${item?.productName},`}</p>
                    );
                  })}
                </div>
              );
            }}
          />

          <AgGridColumn
            headerName="Receipt"
            minWidth={90}
            maxWidth={90}
            cellRendererFramework={(params) => <Action params={params} />}
          />

          <AgGridColumn
            headerName="Action"
            minWidth={80}
            sortable={true}
            cellRendererFramework={(params) => {
              //PAYMENT_PROCESSED

              return (
                <>
                  {params?.data?.refundStatus === "IN_PROCESS" ? (
                    <div className="flex w-16 h-10 items-center">
                      <div className="border-2 border-gray-200 rounded-l-lg p-1 ">
                        <TiTick
                          className="w-5 h-5 cursor-pointer text-green"
                          onClick={() => handleApprove(params?.data)}
                        />
                      </div>
                      <div className="border-2 border-gray-200 rounded-r-lg p-1">
                        <MdCancel
                          className="w-5 h-5 cursor-pointer"
                          onClick={() => rejected(params?.data)}
                        />
                      </div>
                    </div>
                  ) : params?.data?.refundStatus === "REFUND_SUBMITTED" ? (
                    <div className="flex w-16 h-10 items-center justify-center">
                      <div className="border-2 border-gray-200 rounded-lg p-1 ">
                        <AiOutlineFileDone
                          className="w-5 h-5 cursor-pointer text-green"
                          onClick={() => submit(params?.data)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex w-16 h-10 items-center justify-center">
                      <div className="border-2 border-gray-200 rounded-lg p-1 ">
                        <BiErrorCircle className="w-5 h-5 cursor-pointer text-green" />
                      </div>
                    </div>
                  )}
                </>
              );
            }}
          />
        </AgGridReact>
      </div>
      {/* {data?.headers?.["x-total-count"] > 0 && ( */}
      <div className="relative mt-2 flex justify-center item-center">
        {/* <select
          onChange={(e) => handlePageSizeChange(Number(e.target.value))}
          className="mr-1"
        >
          {itemsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option} per page
            </option>
          ))}
        </select> */}
        <Pagination
          count={
            Math.ceil(data?.headers?.["x-total-count"] / itemsPerPage) || "0"
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
        {open && (
          <RefundWarningModal
            open={open}
            handleClose={handleClose}
            handleConfirm={() => approved(currentData)}
          />
        )}
      </div>
      {/* )} */}
    </div>
  );
}

export default Refunded;
