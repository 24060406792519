import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import Pagination from "@mui/material/Pagination";
import Action from "./Action";
import DashboardRoute from "./DashboardRoute";
import { getTime } from "../../../../../../../logic/useGetTime";

function ProcessedTasks({ url, viewUrl, listUrl, refresh, setRefresh }) {
  const history = useHistory();

  const [allCount, setAllCount] = useState();
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const fetchData = async () => {
    try {
      const requestUrl = `${listUrl}?sort=createdDate,desc&page=${
        activePage - 1
      }&size=10&eventStatus.in=APPROVED`;

      const res = await axios({
        method: "GET",
        url: requestUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading } = useQuery(
    ["processedDecisionEvents", activePage, refresh],
    async () => fetchData(),
    {
      enabled: !!activePage,
      refresh,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  if (isLoading) {
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={isLoading}
            size={150}
          />
        </div>
      </div>
    );
  }

  return (
    <div className=" w-full h-[calc(100vh-184px)]">
      <div className="ag-theme-alpine mt-2 w-full h-full text-left py-8">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            resizable: true,
          }}
          rowHeight={50}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data}
        >
          <AgGridColumn
            headerName="Event"
            field="scaleInternalCode"
            minWidth={250}
            sortable={true}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} name="scaleInternalCode" />
            )}
          />
          <AgGridColumn
            headerName="Timestamp"
            field="eventTime"
            cellRendererFramework={(params) => {
              return (
                <p
                  className={`${
                    params?.data?.eventType === "Sensor Event"
                      ? "list_font_style"
                      : ""
                  }`}
                >
                  {getTime(
                    params?.data?.eventTime,
                    params?.data?.storeTimeZone
                  )}
                </p>
              );
            }}
          />
          <AgGridColumn
            headerName="Item Name"
            field="itemName"
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} name="finalProductNames" />
            )}
          />
          <AgGridColumn headerName="SessionRef" field="sessionRef" />
          <AgGridColumn headerName="Decision Status" field="decisionState" />
          <AgGridColumn headerName="Store Code" field="retailStoreCode" />
          <AgGridColumn
            headerName="Action"
            field="action"
            minWidth={80}
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                through={"processedDecisionEvents"}
              />
            )}
          />
        </AgGridReact>
      </div>
      <div className="mb-9 right-12 flex justify-center item-center">
        <Pagination
          count={
            allCount % 10 === 0
              ? parseInt(allCount / 10)
              : parseInt(allCount / 10) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default ProcessedTasks;
