import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-toggle/style.css";
import DashboardRoute from "./DashboardRoute";
import moment from "moment";
import Action from "./Action";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import SearchData from "../../../../../../components/SearchDataMany";
import MobileDatetimePicker from "../../../../../../components/MobileDateTimepicker";
import { BiSearchAlt } from "react-icons/bi";
import Pagination from "@mui/material/Pagination";
import { useQuery } from "react-query";
import { MdClear } from "react-icons/md";
import { getTime } from "../../../../../../logic/useGetTime";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { MerchantsDetails } from "../../../../../../utils/action_types";

function FullfillmentEvents({ url, viewUrl, editUrl, listUrl, refresh }) {
  const [maxSize, setMaxSize] = useState(50);
  const [allCount, setAllCount] = useState();
  const [filteredData, setFilteredData] = useState({
    retailerStore: "",
    cartRef: "",
  });
  const [searchData, setSearchData] = useState({
    retailerStore: "",
    cartRef: "",
  });
  const [loading, setLoading] = useState(false);

  const [input, setInput] = useState({
    retailerStore: "",
    cartref: "",
    to: moment().add(-24, "hours"),
    from: new Date(),
    type: "",
  });

  const [activePage, setActivePage] = useState(1);
  const [selectedTime, setSelectedTime] = useState({
    to: "",
    from: "",
  });

  const history = useHistory();

  const findUrl = (name) => {
    switch (name) {
      case "cartref":
        return `${listUrl}?sort=entryTime,desc&page=${
          activePage - 1
        }&size=${maxSize}&storeCode.equals=${
          searchData?.retailerStore?.internalCode
        }&cartRef.equals=${
          input?.cartref
        }&eventStatus.in=CHECKEDOUT&userType.equals=EMPLOYEE`;

      case "retailerStore":
        return `${listUrl}?sort=entryTime,desc&page=${
          activePage - 1
        }&size=${maxSize}&storeCode.equals=${
          searchData?.retailerStore?.internalCode
        }&eventStatus.in=CHECKEDOUT&userType.equals=EMPLOYEE`;

      case "from":
        return `${listUrl}?sort=entryTime,desc&page=${
          activePage - 1
        }&size=${maxSize}&entryTime.greaterThanOrEqual=${moment(input?.from)
          .utc()
          .format()}&entryTime.lessThanOrEqual=${moment(input?.to)
          .utc()
          .format()}&eventStatus.in=CHECKEDOUT&userType.equals=EMPLOYEE`;

      default:
        return `${listUrl}?sort=entryTime,desc&page=${
          activePage - 1
        }&size=${maxSize}&eventStatus.in=CHECKEDOUT&userType.equals=EMPLOYEE`;
    }
  };

  const searchDataFn = (name, data) => {
    setSearchData({ ...searchData, [name]: data });
    setInput({ ...input, [name]: data?.name });
    setFilteredData({
      cartref: "",
      retailer: "",
    });
  };

  const fetchData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: findUrl(input?.type),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      });
      setAllCount(res?.headers?.["x-total-count"]);
      return res.data;
    } catch ({ message }) {
      if (message === "Request failed with status code 401") {
        history.push("/login");
      } else {
        toast.error(message);
      }
    }
  };

  const { data, isLoading } = useQuery(
    [
      "matchedExit",
      activePage,
      input?.cartref,
      searchData?.retailerStore,
      selectedTime,
      refresh,
    ],
    async () => fetchData(input?.type),
    {
      enabled:
        !!activePage ||
        !!searchData?.retailerStore ||
        !!input?.cartref ||
        !!selectedTime ||
        refresh,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const onChangeInput = (e, name) => {
    const value = e.target.value;
    setInput({ ...input, [name]: value, type: name });

    if (name === "retailerStore") {
      setLoading(true);

      axios({
        method: "GET",
        url: `${
          process.env.REACT_APP_BASE_URL
        }/_search/retail-stores?name.contains=${value.toLowerCase()}*`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user-info")}`,
        },
      }).then((res) => {
        setFilteredData({ ...filteredData, [name]: res?.data });

        setLoading(false);
      });
    }
  };

  const onChangeTime = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const setTime = () => {
    setSelectedTime({
      to: input?.to,
      from: input?.from,
    });
    setInput({ ...input, type: "from" });
  };

  const clear = () => {
    setInput({
      retailerStore: "",
      cartref: "",
      type: "",
      to: moment().add(-24, "hours"),
      from: new Date(),
    });
    setFilteredData({ retailerStore: "", cartref: "" });
    setSearchData({
      retailerStore: "",
      cartRef: "",
    });
    setSelectedTime({
      to: "",
      from: "",
    });
  };

  if (isLoading)
    return (
      <div className="bg-black bg-opacity-80 absolute inset-0 flex items-center justify-center">
        <div className="w-full h-20 absolute z-10 text-white flex flex-col items-center justify-center">
          <FadeLoader
            color="#FFFFFF"
            className=""
            loading={isLoading}
            size={150}
          />
        </div>
      </div>
    );

  return (
    <div className=" w-full h-[calc(100vh-184px)]">
      <div className="flex h-10 w-full items-center mt-2 justify-between py-8 px-4">
        <div className="w-auto flex space-x-6 items-center">
          <div className="w-72 flex justify-end items-center">
            <SearchData
              name="retailerStore"
              label={"Retailer Store"}
              input={input}
              setInput={setInput}
              onChangeInput={onChangeInput}
              filteredData={filteredData}
              searchDataFn={searchDataFn}
              setSearchData={setSearchData}
              loading={loading}
              margin="ml-[250px]"
            />
          </div>

          <div className="w-72 flex justify-end items-center">
            <input
              name="cartref"
              placeholder="CartRef"
              value={input?.cartref}
              onChange={(e) => onChangeInput(e, "cartref")}
              className="w-full h-10  focus:outline-none rounded px-4 border border-black"
            />
            {input["cartref"]?.length > 0 ? (
              <MdClear
                size={24}
                className="cursor-pointer -ml-[34px]"
                onClick={clear}
              />
            ) : (
              <BiSearchAlt
                size={24}
                className="cursor-pointer -ml-[34px] text-blue-500"
              />
            )}
          </div>
        </div>

        <div className="w-[510px] flex space-x-6 items-center -mt-4 pr-[12px]">
          <MobileDatetimePicker
            value={input?.to}
            clearIcon={false}
            // minDateTime={new Date()}
            maxDateTime={new Date()}
            onChangeTime={onChangeTime}
            name="to"
            label={"To"}
          />

          <MobileDatetimePicker
            value={input?.from}
            clearIcon={false}
            // minDateTime={new Date()}
            maxDateTime={new Date()}
            onChangeTime={onChangeTime}
            name="from"
            label={"From"}
          />
          <BiSearchAlt size={32} onClick={setTime} className="cursor-pointer" />
          {/* <GrRefresh size={28} onClick={clear} className="cursor-pointer" /> */}
        </div>
      </div>

      <div className="ag-theme-alpine  w-full h-[100%] text-left">
        <AgGridReact
          defaultColDef={{
            flex: 1,
            resizable: true,
          }}
          rowHeight={200}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          rowData={data.filter((ele) => ele.cartRef.includes("Fulfillment"))}
          onRowClicked={(params) => {
            localStorage.setItem("navigate", "processedEntryEvents");
            history.push({
              pathname: false
                ? "/support/shopper-activity/shopperExitEvent"
                : viewUrl,
              state: { data: { ...params.data, through: "processedEntry" } },
            });
          }}
        >
          <AgGridColumn
            headerName="Name"
            field="name"
            sortable={true}
            minWidth={220}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            checkboxSelection={false}
            cellRendererFramework={(params) => (
              <DashboardRoute params={params} name="name" />
            )}
          />

          <AgGridColumn
            headerName="Image"
            field="image"
            cellRendererFramework={(params) => (
              <div className="h-full w-full">
                <img
                  src={params?.data?.userImagePaths[0]}
                  alt="shopper img"
                  className="w-full h-[200px]"
                />
              </div>
            )}
          />

          {/* <AgGridColumn
            headerName="Mobile"
            field="formattedPhone"
            sortable={true}
          /> */}

          <AgGridColumn
            headerName="Merchant"
            field="retailStoreCode"
            sortable={true}
            cellRendererFramework={(params) => {
              console.log(
                "merchantPhotu",
                MerchantsDetails.hasOwnProperty(params?.value)
                  ? MerchantsDetails.hasOwnProperty(params?.value)?.imgUrl
                  : "/images/noImage.jpg"
              );
              return (
                <div>
                  <img
                    src={
                      MerchantsDetails.hasOwnProperty(params?.value)
                        ? MerchantsDetails?.[params?.value]?.imgUrl
                        : "/images/noImage.jpg"
                    }
                    width="50px"
                    height="50px"
                    alt="/images/noImage.jpg"
                    // className="w-full h-[200px]"
                    // className="mt-3"
                  />
                </div>
              );
            }}
          />

          <AgGridColumn headerName="Login" field="login" sortable={true} />

          <AgGridColumn
            headerName="Entry Time"
            field="entryTime"
            sortable={true}
            minWidth={160}
            cellRendererFramework={(params) => {
              return (
                <p className="">
                  {getTime(
                    params?.data?.entryTime,
                    params?.data?.storeTimeZone
                  )}
                </p>
              );
            }}
          />

          <AgGridColumn
            headerName="Exit Timestamp"
            field="exitTime"
            sortable={true}
            minWidth={160}
            cellRendererFramework={(params) => {
              return (
                <>
                  <p>
                    {getTime(
                      params?.data?.exitTime,
                      params?.data?.storeTimeZone
                    )}
                  </p>
                </>
              );
            }}
          />

          <AgGridColumn headerName="Cart Ref" field="cartRef" sortable={true} />

          <AgGridColumn
            headerName="Shopper #"
            field="noOfGroupShoppers"
            maxWidth={100}
            sortable={true}
            cellRendererFramework={(params) => {
              return (
                <p className="px-6">{`${params?.data?.noOfGroupShoppers}`}</p>
              );
            }}
          />

          <AgGridColumn headerName="Store Code" field="retailStoreCode" />
            
          <AgGridColumn
            headerName="Action"
            field="action"
            cellRendererFramework={(params) => (
              <Action
                params={params}
                url={url}
                viewUrl={viewUrl}
                editUrl={editUrl}
                through={"processedEntry"}
              />
            )}
          />
        </AgGridReact>
      </div>

      <div className="mb-9 right-12 flex justify-center item-center">
        <Pagination
          count={
            allCount % maxSize === 0
              ? parseInt(allCount / maxSize)
              : parseInt(allCount / maxSize) + 1
          }
          showFirstButton
          showLastButton
          page={activePage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default FullfillmentEvents;
