import moment from "moment";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const OrdersTabContext = createContext({
  allTabInput: [],
  setAllTabInput: () => {},
  allTabContainsSearch: [],
  setAllTabContainsSearch: () => {},
  activeTabValue: [],
  setActiveTabValue: () => {},
  allTabSelectedDateRange: [],
  setAllTabSelectedDateRange: () => {},
  allTabActivePage: [],
  setAllTabActivePage: () => {},
  allTabItemsPerPage: [],
  setAllTabItemsPerPage: () => {},
  activeMerchant: [],
  setActiveMerchant: () => {},
  cardFilters: [],
  setCardFilters: () => {},
  activeCardDetails: [],
  setActiveCardDetails: () => {},
  activePaymentStatus: [],
  setActivePaymentStatus: () => {},
  // itemsPerPageAllTabOptions: [],
});

const OrdersTabProvider = ({ children }) => {
  // ------------------ All tab ---------------------------
  const itemsPerPageAllTabOptions = [10, 15, 20];

  const [allTabInput, setAllTabInput] = useState({
    "firstName.contains": "",
    "orderNumber.equals": "",
    "status.equals": "",
    "lastName.contains": "",
    "login.contains": "",
    "sessionRef.contains": "",
    "storeCode.equals": "",
    "createdDateFrom.greaterThanOrEqual": "",
    "createdDateTo.lessThanOrEqual": "",
    "phone.contains": "",
    "email.contains": "",
    "cardBrand.contains": "",
    "(range)": "",
    "last4Digit.equals": "",
  });

  const [allTabContainsSearch, setAllTabContainsSearch] = useState({
    "firstName.contains": "",
    "lastName.contains": "",
    "login.contains": "",
    "sessionRef.contains": "",
  });

  const [cardFilters, setCardFilters] = useState({
    "cardBrand.contains": "",
    "last4Digit.equals": "",
  });

  const [allTabSelectedDateRange, setAllTabSelectedDateRange] = useState([
    null,
    null,
  ]);

  const [activeMerchant, setActiveMerchant] = useState("");

  const [activeCardDetails, setActiveCardDetails] = useState("");

  const [allTabActivePage, setAllTabActivePage] = useState(1);

  const [allTabItemsPerPage, setAllTabItemsPerPage] = useState(
    itemsPerPageAllTabOptions[0]
  );

  const [activeTabValue, setActiveTabValue] = React.useState(0);

  const [activePaymentStatus, setActivePaymentStatus] = useState("");

  useEffect(() => {
    setActiveMerchant(activeMerchant);
  }, [activeMerchant]);

  const memoizedValue = useMemo(
    () => ({
      allTabInput,
      setAllTabInput,
      allTabContainsSearch,
      setAllTabContainsSearch,
      activeTabValue,
      setActiveTabValue,
      allTabSelectedDateRange,
      setAllTabSelectedDateRange,
      allTabActivePage,
      setAllTabActivePage,
      allTabItemsPerPage,
      setAllTabItemsPerPage,
      itemsPerPageAllTabOptions,
      setActiveMerchant,
      activeMerchant,
      cardFilters,
      setCardFilters,
      activeCardDetails,
      setActiveCardDetails,
      activePaymentStatus,
      setActivePaymentStatus,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeTabValue,
      allTabActivePage,
      allTabContainsSearch,
      allTabInput,
      allTabItemsPerPage,
      allTabSelectedDateRange,
      cardFilters,
      activeCardDetails,
      activePaymentStatus,
      // itemsPerPageAllTabOptions,
    ]
  );

  return (
    <OrdersTabContext.Provider value={memoizedValue}>
      {children}
    </OrdersTabContext.Provider>
  );
};

function useOrdersTabProvider() {
  const context = useContext(OrdersTabContext);
  if (context === undefined) {
    throw new Error("Please provide Search data provider");
  }
  return context;
}

export { OrdersTabProvider, useOrdersTabProvider };
